<template>
  <div class="contract-cont">
    <div class="group-name">
      <div class="group-line">
        <div class="group-line-in"></div>
      </div>
      <div class="group-title">您所查询的优惠券信息如下</div>
    </div>

    <div class="list">
      <ul class="box shadow" v-for="(item, index) in list" :key="index">
        <li class="item">
          <div class="label">服务名称</div>
          <div class="text">{{item.policyName}}</div>
        </li>
        <li class="item">
          <div class="label">手机品牌</div>
          <div class="text">{{item.phoneBrand}}</div>
        </li>
        <li class="item">
          <div class="label">生效时间</div>
          <div class="text">{{item.startTime}}</div>
        </li>
        <li class="item">
          <div class="label">失效时间</div>
          <div class="text">{{item.endTime}}</div>
        </li>
        <li class="item">
          <div class="label">可用优惠券数量</div>
          <div class="text">{{item.num}}</div>
        </li>
        <li class="item" v-if="item.has == 1">
          <div class="label">立即使用</div>
          <div class="text">
            <cube-button class="btn" @click="goPath('/coupon/coupon/' + item.policyId)">进入</cube-button>
          </div>
        </li>
      </ul>

      <div class="nodata" v-show="list.length == 0">无有效服务信息</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ScarchImei',
  data () {
    return {
      imei: this.$route.params.imei ? this.$route.params.imei : '',
      list: [],
    }
  },
  mounted () {
    this.getInfo()
    this.$cookies.set('imei', this.imei);
  },
  methods: {
    /**
     * 获取合同列表
     */
    getInfo () {
      const vm = this;
      if(! this.imei){
        this.$createToast({
          txt: '无IMEI信息',
          type: 'error'
        }).show()
        return
      }
      let postData = {
        IMEL: this.imei
      }
      vm.$store.commit('updateLoader', true);
      this.$http
        .post("/policy/queryIMEL", postData)
        .then(function(response) {
          vm.$store.commit('updateLoader', false);
          if (response.data.header.code == "A1000") {
            if(response.data.body.queryImelList && response.data.body.queryImelList.length > 0){
              let list = [];
              response.data.body.queryImelList.forEach((e, i)=>{
                let obj = {...e};
                if(e.type == '8' || e.type == '9' || e.type == '10'){
                  obj.type = true;
                  if(e.type == '8'){
                    obj.typeName = '保单过期';
                  }else if(e.type == '9'){
                    obj.typeName = '保单中止';
                  }else if(e.type == '10'){
                    obj.typeName = '渠道退保';
                  }
                }else{
                  obj.type = false;
                  obj.typeName = '有效';
                }
                list.push(obj)
              })
              vm.formatData(list)
            }else{
              vm.$createToast({
                txt: '当前IMEI未查询到服务信息，请核对IMEI',
                type: 'warn'
              }).show()
            }
          } else {
            vm.$createToast({
              txt: response.data.header.msg,
              type: 'warn'
            }).show()
          }
        })
        .catch(function() {
          vm.$store.commit('updateLoader', false);
          vm.$createToast({
            txt: '网络错误',
            type: 'error'
          }).show()
        });
    },
    /**
     * 合同数据处理
     */
    formatData (list) {
      this.list = list.filter(e => {
        if(! e.type){
          return e
        }
      })
    },
    /**
     * 跳转页面
     */
    goPath (path) {
      this.$router.push(path)
    },
  },
}
</script>

<style lang="scss" scoped>
.box{
  margin: 0 30px 30px 30px;
  padding: 0 30px;
  font-size: 30px;
  border-radius: 14px;
  .item{
    display: flex;
    border-top: 1px solid #DEDEDE;
    &:first-child{
      border: none;
      .text{
        color: #208170;
        font-weight: 700;
      }
    }
    padding: 21px 0;
    line-height: 48px;
    .label{
      padding-right: 20px;
    }
    .text{
      flex: 10;
      text-align: right;
    }
  }
}
.btn{
  display: inline-block;
  font-size: 26px;
  width: 148px;
  border-radius: 30px;
  text-align: center;
  padding: 0;
  line-height: 48px;
  background-color: #208170;
}
</style>